<template>
  <div class="content">

    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="分类名称">
            <el-input
              v-model="search.search"
              maxlength="30"
              placeholder="请输入分类名称"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="分类名称" prop="name" />
            <ElTableColumn label="创建人"  prop="create_user_name" />
            <ElTableColumn label="创建时间"  prop="create_time" />
            <ElTableColumn label="修改时间"  prop="update_time" />
            <ElTableColumn label="操作"  fixed="right">
              <template  slot-scope="{ row }" >
                <span class="theme " @click="tapBtn('update',row.id)"> 编辑</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import pagination from '@/components/Pagination'
import { categoryList,deleteGoods } from '@/api/goods';
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  search = ''; // 名称
}
export default {
  name: 'ClassifyList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: ''
    }
  },

  mounted () {
    this.getList()
  },
  methods: {
    // 获取列表
    getList (option) {
      if (option === 'search') this.page = new Page();
      categoryList({...this.search,...this.page}).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
      })
    },
    // 点击详情
    tapBtn (type,id) {
       switch (type) {
        case 'update':
          this.$router.push('./addClassify?id=' + id)
          break
      }
    },

  }
}
</script>

<style lang="scss" scoped>

.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px  40px  3px 0 ;
}
.accountCon{
    background: #fff;
    padding: 10px 0px 80px;
}
</style>
